<template>
  <b-container>
    <h2>{{title || 'secret'}}</h2>
    <v-md-editor v-model="text" height="600px" @save="updateSecret" />

  </b-container>
</template>

<script>

export default {
  name: 'SecretsCreate',
  components: {
  },
  data() {
    return {
      title: '',
      text: '',
    };
  },
  async mounted() {
    this.getSecret();
  },
  methods: {
    async getSecret() {
      const token = await localStorage.getItem('token')
      this.axios
        .get('secret/get', {
          params: {
            id: this.$route.params.id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((r) => {
          this.title = r.data.title;
          this.text = r.data.text;
        })
        .catch(() => {
        })
    },
    async updateSecret() {
      const token = await localStorage.getItem('token')
      this.axios
        .patch("secret/update", {
          title: this.title,
          text: this.text,
        }, {
          params: {
            id: this.$route.params.id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(() => {
            this.toast('Success','Saved your changes', 'success');
          })
          .catch((/*err*/) => {
          });
    }
  },
}
</script>

<style scoped>

</style>